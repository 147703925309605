@import 'color';

.aProposMain {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: absolute;
    top: 0;
    left: 20%;
    right: 0;
    bottom: 0;
    color: $main-text;
    width: 60vw;  /* adjusted width */
    font-size: 24px;
    opacity: 0.3;
    transition: opacity 0.3s ease;
    height: 400px;
    &:hover {
        opacity: 1;
    }
}


.backdrop{
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 0;
    background-color: #858383;
    opacity: 0.3;
    width:100vw;
    height: 100vw;
}

.backgroundImgAPropos{
    background-image: url('../img/backgroundApropos.svg');
    background-size: cover;
    background-position: center;
    height: 100vh;
}

@media only screen and (max-width: 800px) {
    .aProposMain{
        top: 200px;
        opacity: 1;
    }

    .backdrop{
        height:100%;
    }
}