@import '../styles/color.scss';

header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    position: relative;

    .box{
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        transition: transform 0.3s ease;

        img{
            width: 20%;
            min-width: 200px;
            z-index: 1;
            margin-top: -10px;
        }
    }

    .nav-link{
        text-decoration: none;
        z-index: 1;
    }

    .active .accueil {
        text-decoration: underline;
    }

    .active .aProposHeader {
        text-decoration: underline;
    }


    .hover-element{
        margin: 40px;
        font-size: 24px;

        p{
            color: white;
            white-space: nowrap;
        }
    }
}

@media only screen and (max-width: 500px) {
    header .box img{
        min-width: 100px !important;
    }
}