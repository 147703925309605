@import 'color';

.backgroundImgContact{
    background-image: url('../img/backgroundContact.svg');
    background-size: cover;
    background-position: center;
    height: 100vh;
}

.contactForm{
    position: absolute;
    top: 4vw;
    left: 26vw;
    font-size: 24px;
    width: 600px;
    opacity: 0.3;
    transition: opacity 0.3s ease;
    &:hover{
        opacity: 1;
    }

    .btnEnvoie{
        margin-top:20px;
        font-family: 'avera';
        color: $main-text;
        background-color: $main-background;
        font-size: 20px;
        border: none;
        opacity: 0.3;
        transition: opacity 0.3s ease;

        &:hover{
            opacity: 1;
        }
    }
}

@media only screen and (max-width: 1200px) {
    .contactForm{
        left: 10vw;
        width: 320px;
    }

    nav .contactForm {
            opacity: 1;
    }

    nav .contactForm .btnEnvoie{ 
        opacity: 1;
    }
}