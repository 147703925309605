@import 'color';

nav{
    position: relative;

    .backgroundImgGallery{
        background-image: url('../img/backgroundGallery.svg');
        background-size: cover;
        background-position: center;
        height: 100vh;
    }

    .NavLink {
        text-decoration: none;

        &:hover,
        &:focus,
        &:visited,
        &:active {
            text-decoration: none;
        }
    }

    .NavLink p {
        text-decoration: none;

        &:hover,
        &:focus,
        &:active {
            text-decoration: none;
        }
    }

    .backgroundImage{
        width: 100%;
        height: 100%;
    }

    .presentationText{
        display: flex;
        flex-direction: row;
        margin-left: 5%;
        margin-top: 10%;
        position: relative;
        cursor: default;
        & > * {
            transition: opacity 0.3s ease;
        }

        p{
            width: 300px;
            font-size: 24px;
            text-align: center;
            color:black;
            opacity: 0.3;
        }

        &:hover p {
            opacity: 1;
        }
    }

    .bookContainer{
        position: absolute;
        top: 0%;
        height: 100px;
    }

    
    .rhombus{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100vw;

        .books{
            display: flex;
            flex-direction: column;
            color: $main-text;

            .collection{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                position: absolute;
                right: 20%;

                & > * {
                    transition: opacity 0.3s ease;
                }

                p{
                    margin: 0px;
                    position: absolute;
                    font-size: 28px;
                    opacity: 0.1;
                    color:$main-text;
                }

                &:hover p {
                    opacity: 1;
                }
            }

            .contact{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                position: absolute;
                top: 15vw;
                right: 12vw;

                & > * {
                    transition: opacity 0.3s ease;
                }
                p{
                    position: absolute;
                    font-size: 22px;
                    opacity: 0.1;
                    color:$main-text;
                }

                &:hover p {
                    opacity: 1;
                }
            }

            .auteurices{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                position: absolute;
                top: 28vw;
                right: 5vw;

                & > * {
                    transition: opacity 0.3s ease;
                }
                p{
                    position:absolute;
                    font-size: 22px;
                    opacity: 0.1;
                    color:$main-text;
                }

                &:hover p {
                    opacity: 1;
                }
            }

        }
    }

}

@media only screen and (max-width: 1100px) {
    body{
        width: 107vw;
    }
    
    nav .rhombus .books .contact{
        top: 1vw;
        right: -3vw;
    }

    nav .rhombus .books .auteurices{
        top: 36vw;
        right: -2vw;
    }

    .rhombus a p {
            opacity: 1;
        }

    p {
        opacity: 1 !important;
    }
}

@media only screen and (max-width: 750px) {
    body{
        width: 107vw;
    }

    nav .rhombus .books .collection{
        top: 93vw;
        right: 45vw;
    }

    nav .rhombus .books .contact{
        top: 114vw;
        right: 11vw;
    }

    nav .rhombus .books .auteurices{
        top: 83vw;
        right: -7vw;
    }

    polygon{
        fill: #2f3338 !important;
    }

    .rhombus p {
        opacity: 1 !important;
    }
}