@import 'color';

nav {
    .backgroundImgCollection{
        background-image: url('../img/backgroundCollection.svg');
        background-size: cover;
        background-position: center;
        height: 100vh;
    }

    .booksCover{
        position: absolute;
        top: 0%;
        width: 100%;
        height: 100%;

        button {
            position: absolute;
            text-align: center;
            background: none;
            color: inherit;
            border: none;
            padding: 0;
            font: inherit;
            cursor: pointer;
            outline: inherit;
            -webkit-appearance: none; /* for Chrome and Safari */
            -moz-appearance: none;    /* for Firefox */
            appearance: none;         /* standard syntax */
        }

        .naissanceBox{
            display: flex;
            justify-content: center;
            align-items: center;
            position: fixed;
            top: 450px;
            left:35%;

            p{
                color: black;
                font-size: 24px;
                padding-top: 70px;
                height: 100px;
                opacity: 0.3;
                transition: opacity 0.3s ease;
                &:hover{
                    opacity: 1;
                }
            }
        }

        .confessionBox{
            display: flex;
            justify-content: center;
            align-items: center;
            position: fixed;
            top: 600px;
            left:45%;

            p{
                color: black;
                font-size: 24px;
                padding-top: 60px;
                width: 90px;
                height: 150px;
                opacity: 0.3;
                transition: opacity 0.3s ease;
                &:hover{
                    opacity: 1;
                }
            }
        }
    }
}

@media only screen and (max-width: 1000px) {
    nav .booksCover .naissanceBox{
        left:25%;
    }

    nav .booksCover .confessionBox{
        left:37%;
    }
}

@media only screen and (max-height: 1080px) {
    nav .booksCover .naissanceBox{
        left:25%;
        top: 30%;
    }

    nav .booksCover .confessionBox{
        left:45%;
        top: 35%;
    }
}

@media only screen and (max-width: 480px) {
    nav .booksCover .naissanceBox{
        left:30%;
        top: 25%;
    }

    nav .booksCover .confessionBox{
        right:20%;
        top: 40%;
    }
}