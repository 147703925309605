.backgroundImgAuteurices{
    background-image: url('../img/backgroundAuteurices.svg');
    background-size: cover;
    background-position: center;
    height: 100vh;
}

.btnAuteurice{
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    -webkit-appearance: none; /* for Chrome and Safari */
    -moz-appearance: none;    /* for Firefox */
    appearance: none;         /* standard syntax */
    opacity: 0.3;
    transition: opacity 0.3s ease;
        &:hover{
            opacity: 1;
        }
}

.auteurice{
    font-size: 24px;
}

.margot{
    position: absolute;
    right: 5%;
    top: 20vw;
}

.enfant{
    position: absolute;
    right: 10%;
    top: 10vw;
}

.manon{
    position: absolute;
    right: 18%;
    top: 30vw;
}

@media only screen and (max-width: 1350px) {  
    nav .presentationText p{
        background-color: rgba(228,228,228,0.8);
        border-radius: 10px;
    }

    .enfant{
        top:10%;
        left: 30%;
    }

    .margot{
        top:25%;
        left: 30%;
    }

    .manon{
        top:40%;
        left: 5%;
    }

    .btnAuteurice{
        opacity: 1;
    }
}